import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { Container, Row, Col, Form, Button } from 'react-bootstrap';

const SecondPage = () => (
  <Layout>
    <SEO title="Contact The Doctor" />
    <div class="subhead">
      <Container>
        <h1>Contact The Doctor</h1>
      </Container>
    </div>

    <Container>
        <Row>
          <Col lg={6}>
            <Form 
                name="contact v2"
                method="post"
                action="/contact-success/"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit="submit"
            >
                    {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                    <input type="hidden" name="form-name" value="contact v2" />
                    <p hidden>
                    <label>
                        Don’t fill this out: <input name="bot-field" />
                    </label>
                    </p>
                     


                        <Row>
                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label>First Name</Form.Label>
                                    <Form.Control 
                                        type="text" name="first-name"
                                     />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                            <Form.Group>
                                    <Form.Label>Last Name</Form.Label>
                                    <Form.Control 
                                       type="text" name="last-name"

                                     />
                            </Form.Group>
                            </Col>
                        </Row>


                            
                        <Form.Group>
                            <Form.Label>Email address</Form.Label>
                            <Form.Control 
                                required
                                type="email" 
                                name="email" 
                            />
                        </Form.Group>

                        
                        <Form.Group>
                            <Form.Label>Comments, questions, praise…</Form.Label>
                            <Form.Control 
                                required
                                as="textarea" 
                                name="comments-questions-praise"
                                rows="3"
                             />
                        </Form.Group>
                        
                        <Button type="submit">Send your message to the Doctor</Button>
                        </Form>
          </Col>
          <Col lg={6}>
            <p><em>Dr. Humankind would love to hear for you. However, she cannot diagnose or treat anything over a message. If you need something treated or diagnosed, please contact your local health provider.</em></p>
            <p><em>In addition, Dr. Humankind cannot guarantee that she can get back to all e-mail inquiries due to her busy call schedule.  However, she will read every message and welcomes your feedback.  
</em></p>
            <p><em>Finally, most importantly, of course, If you find yourself in a medical emergency or need immediate help, please stop what you're doing and dial 911.</em></p>
          </Col>
        </Row>
        
      </Container>
  </Layout>
)

export default SecondPage
